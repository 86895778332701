<template>
    <div>

        <div class="container-fluid">
            <div class="row ">
                <div class="col-12 p-0">
                    <div class="login-card">
                        <div>
                            <div>
                                <a class="logo">
                                    <img class="img-fluid for-light" src="../assets/images/logo/logo-icon.png"
                                         alt="looginpage"/>
                                    <img class="img-fluid for-dark" src="../assets/images/logo/logo-icon.png"
                                         alt="looginpage"/>
                                </a>
                            </div>
                            <div class="login-main">
                                <form class="theme-form">
                                    <h4>Đăng nhập</h4>
                                    <p>Vui lòng điền tài khoản và mật khẩu</p>
                                    <div class="form-group">
                                        <label class="col-form-label">Tài khoản</label>
                                        <input class="form-control" type="text" required=""
                                               placeholder="Vui lòng nhập tài khoản"
                                               v-model="user.email.value">
                                        <span class="validate-error"
                                              v-if="!user.email.value">{{
                                                user.email.errormsg
                                            }}</span>

                                    </div>
                                    <div class="form-group">
                                        <label class="col-form-label">Mật khẩu</label>
                                        <div class="form-input position-relative">
                                            <input class="form-control input-password" :type="passwordFieldType" name="login[password]"
                                                   required=""
                                                   placeholder="*********" v-model="user.password.value">
                                            <span class="validate-error" v-if="user.password.value.length < 7">{{
                                                    user.password.errormsg
                                                }}</span>

                                            <div class="show-hide" @click="showPassword()"><span :class="show"> </span></div>
                                        </div>
                                    </div>
                                    <span class="validate-error" v-if="user.error.length>0">{{user.error }}</span>
                                    <div class="form-group mb-0">
                                        <div class="checkbox p-0">
                                            <input id="checkbox1" type="checkbox" value="true">
                                            <label class="text-muted" for="checkbox1">Nhớ mật khẩu</label>
                                        </div>
                                        <div class="text-end mt-3">
                                            <button class="btn btn-primary btn-block w-100" type="submit"
                                                    @click.prevent="login">Đăng nhập
                                            </button>

                                        </div>
                                    </div>
                                    <p class="mt-4 mb-0 text-center">Chưa có tài khoản?
                                        <router-link class="ms-2" tag="a" to="/auth/register">
                                            Đăng ký
                                        </router-link>
                                    </p>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { get, post, put, remove } from '/src/m_utils/rest_api';
import error from "auth0-js/src/helper/error";

export default {
    name: 'login',
    computed: {
        error() {
            return error
        }
    },
    data() {
        return {
            passwordFieldType:'password',
            show:'show',
            result: {email: '', password: ''},
            user: {
                email: {
                    value: '',
                    errormsg: ''
                },
                password: {
                    value: '',
                    errormsg: ''
                },
                error:'',
            }
        };
    },

    created() {

    },
    methods: {
        showPassword(){
            this.passwordFieldType = this.passwordFieldType==='password' ? 'text' : 'password';
            this.show = this.show ==='show'?'hide':'show'
        },
        async login() {
            if (!this.user.password.value || this.user.password.value.length < 7) {
                this.user.password.errormsg = 'Vui lòng điền mật khẩu và mật khẩu phải lớn hơn 7 ký tự'
            } else {
                this.user.password.errormsg = ''
            }

            if (!this.user.email.value) {
                this.user.email.errormsg = 'Vui lòng điền tài khoản '
            }
            else {
                this.user.email.errormsg = ''
            }
            if(this.user.email.value && this.user.password.value.length>= 7)
            {
                let url = "/user-pixels/auth/login";
                let params = {
                    username: this.user.email.value,
                    password: this.user.password.value,
                }
                const res = await post(url,params)
                if(res.rc == 0){
                    localStorage.setItem('User', res.user)
                    localStorage.setItem('token', res.token)
                    this.logging = true
                    this.$router.push('/')
                }
                else {
                    this.user.error=res.rd
                }
            }
        },
    },
};
</script>
<style>
.validate-error{
    color: #fc0303;
    font-size: small;
}
</style>