import axios from 'axios';

// Base URL for your API
const BASE_URL = 'https://pixel-api.dtsgroup.com.vn';

// Create an Axios instance with default settings
const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});
// Function to perform a GET request
export const get = async (endpoint, params = {}) => {
    try {
        const response = await api.get(endpoint, { params });
        return response.data;
    } catch (error) {
        console.error('GET request error:', error);
        throw error;
    }
};

// Function to perform a POST request
export const post = async (endpoint, data) => {
    try {
        const response = await api.post(endpoint, data);
        return response.data;
    } catch (error) {
        console.error('POST request error:', error);
        throw error;
    }
};

// Function to perform a PUT request
export const put = async (endpoint, data) => {
    try {
        const response = await api.put(endpoint, data);
        return response.data;
    } catch (error) {
        console.error('PUT request error:', error);
        throw error;
    }
};

// Function to perform a DELETE request
export const remove = async (endpoint) => {
    try {
        const response = await api.delete(endpoint);
        return response.data;
    } catch (error) {
        console.error('DELETE request error:', error);
        throw error;
    }
};

// Usage examples:
// import { get, post, put, remove } from './api';

// get('/path/to/resource').then(data => console.log(data));
// post('/path/to/resource', { key: 'value' }).then(data => console.log(data));
// put('/path/to/resource', { key: 'updatedValue' }).then(data => console.log(data));
// remove('/path/to/resource').then(data => console.log(data));