<template>
    <div>
        <Breadcrumbs title="Danh sách vật phẩm"/>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3">
                    <label class="form-label">Tên vật phẩm</label>
                    <el-input v-model="name_search"/>
                </div>
                <div class="col-md-2 row align-content-end">
                    <el-button type="primary" @click="getDuLieu">
                        Tìm kiếm
                    </el-button>
                </div>
            </div>
            <div class="row justify-content-end" style="margin:5px">
                <el-button type="success" style="width:150px">Thêm mới</el-button>
            </div>
            <div class="row widget-grid mt-3">
                <el-table :data="tableData" style="width: 100%" empty-text="Không tìm thấy vật phẩm">
                    <el-table-column label="STT" type="index" width="100px" :index="getIndex"></el-table-column>
                    <el-table-column label="Tên vật phẩm" prop="itemName"></el-table-column>
                    <el-table-column label="Ảnh">
                        <template #default="scope">
                            <img :src="scope.row.img" alt="Image" style="width: 100px; height: auto;"/>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div v-if="pagging.totalPage>0">
            <el-pagination
                    v-model:current-page="pagging.current_page"
                    v-model:page-size="pagging.size"
                    :page-sizes="[20, 50, 100]"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagging.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
            />
        </div>
    </div>
</template>

<script>
import {get, post, put, remove} from '/src/m_utils/rest_api';

export default {
    data() {
        return {
            name_search: '',
            pagging: {
                current_page: 1,
                size: 20,
                totalPage: 0,
                total: 0,
            },
            start: 0,
            limit: 20,
            rules: {},
            tableData: []
        };
    },
    methods: {
        getIndex(index) {
            return (this.pagging.current_page-1)*this.limit + index + 1
        },
        handleCurrentChange(page) {
            this.pagging.current_page = page;
            this.start = (page - 1) * this.limit
            this.getDuLieu()
        },
        handleSizeChange(size) {
            this.limit = size
            this.pagging.size = size
            this.getDuLieu()
        },
        async getDuLieu() {
            this.$store.dispatch('setLoading', true);
            this.name_search = this.name_search.trim()
            let url = "/pixels-game/user/get-item?start=" + this.start + "&limit=" + this.limit;
            if (this.name_search !== '') {
                url += '&itemName=' + this.name_search.trim()
            }
            this.pagging.totalPage = 0;
            this.pagging.total = 0;
            let param = {}
            try {
                let res = await post(url, param);
                this.$store.dispatch('setLoading', false);
                if (res.rc == 0) {
                    this.tableData= res.item.content;
                    this.pagging.total = res.item.totalElements;
                    this.pagging.totalPage = res.item.totalPages;
                } else {
                    this.tableData = [];
                }
            } catch (error) {
                this.$store.dispatch('setLoading', false);
                console.error("Error fetching data:", error);
                this.tableData = [];
            }
        }
    },
    mounted() {
        this.getDuLieu();
    }
};
</script>

