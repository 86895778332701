import {createWebHistory, createRouter} from "vue-router";
import Body from '../components/body';

/* Dashboards */
import Index from '../pages/dashboard/index';
import Account from '../pages/dashboard/account.vue';
import User from '../pages/dashboard/user.vue';
import Item from '../pages/dashboard/item.vue';
import reportDay from '../pages/report/day.vue';
import reportWeek from '../pages/report/week.vue';
import reportMonth from '../pages/report/month.vue';

// error
import Error400 from '../pages/error/error400';
import Error401 from '../pages/error/error401';
import Error403 from '../pages/error/error403';
import Error404 from '../pages/error/error404';
import Error500 from '../pages/error/error500';
import Error503 from '../pages/error/error503';


/* Auth */
import login from '../auth/login';
import Register from '../auth/register';


const routes = [
    {
        path: '/',
        component: Body,
        children: [
            {
                path: '/',
                name: 'indexRoot',
                component: Index,
                meta: {
                    title: 'Pixels CMS',
                }
            },
            {
                path: 'account-game',
                name: 'accountRoot',
                component: Account,
                meta: {
                    title: 'Danh sách tài khoản trong game| Pixels CMS',
                }
            },
            {
                path: 'user',
                name: 'userRoot',
                component: User,
                meta: {
                    title: 'Quản trị tài khoản hệ thống | Pixels CMS',
                }
            },
            {
                path: 'item',
                name: 'itemRoot',
                component: Item,
                meta: {
                    title: 'Quản trị vật phẩm | Pixels CMS',
                }
            },
            {
                path: 'report-day',
                name: 'reportDayRoot',
                component: reportDay,
                meta: {
                    title: 'Báo cáo theo ngày | Pixels CMS',
                }
            },
            {
                path: 'report-week',
                name: 'reportWeekRoot',
                component: reportWeek,
                meta: {
                    title: 'Báo cáo theo tuần | Pixels CMS',
                }
            },
            {
                path: 'report-month',
                name: 'reportMonthRoot',
                component: reportMonth,
                meta: {
                    title: 'Báo cáo theo tháng | Pixels CMS',
                }
            },

        ]
    },
    {
        path: '/error-400',
        name: 'Error400',
        component: Error400,
        meta: {
            title: 'Error400 | Pixels CMS',
        }
    },
    {
        path: '/error-401',
        name: 'Error401',
        component: Error401,
        meta: {
            title: 'Error401 | Pixels CMS',
        }
    },
    {
        path: '/error-403',
        name: 'Error403',
        component: Error403,
        meta: {
            title: 'Error403 | Pixels CMS',
        }
    },
    {
        path: '/error-404',
        name: 'Error404',
        component: Error404,
        meta: {
            title: 'Error404 | Pixels CMS',
        }
    },
    {
        path: '/error-500',
        name: 'Error500',
        component: Error500,
        meta: {
            title: 'Error500 | Pixels CMS',
        }
    },
    {
        path: '/error-503',
        name: 'Error503',
        component: Error503,
        meta: {
            title: 'Error503 | Pixels CMS',
        }
    },
    {
        path: '/auth',
        children: [
            {
                path: 'login',
                name: 'Login 1',
                component: login,
                meta: {
                    title: ' Đăng nhập  | Pixels CMS',
                }
            },
            {
                path: 'register',
                name: 'register 1',
                component: Register,
                meta: {
                    title: ' Đăng ký | Pixels CMS',
                }
            }
        ]
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes,
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }

    const authPaths = ['/auth/login', '/auth/register'];
    const userLoggedIn = localStorage.getItem('User');

    if (authPaths.includes(to.path)) {
        // Nếu người dùng đã đăng nhập, không cho phép truy cập trang login và register
        if (userLoggedIn) {
            return next('/');
        } else {
            return next();
        }
    }

    // Nếu người dùng chưa đăng nhập, chuyển hướng đến trang login
    if (!userLoggedIn) {
        return next('/auth/login');
    }

    // Kiểm tra nếu router không tồn tại
    if (!router.getRoutes().some(route => route.path === to.path)) {
        return next('/error-404');
    }

    next();
});
export default router