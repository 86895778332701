<template>
    <div>
        <Breadcrumbs main="Báo cáo thống kê" title="Báo cáo dữ liệu của các tài khoản theo ngày"/>
        <div class="container-fluid">
            <el-form
                    ref="formSearch"
                    style="width: 100%"
                    :model="searchForm"
                    :rules="rules"
                    label-width="auto"
                    class="demo-ruleForm"
                    label-position="left"
            >
                <div class="row">
                    <div class="col-md-3">
                        <label class="form-label">Đối tác</label>
                        <el-form-item prop="branch">
                            <el-select
                                    v-model="searchForm.branch"
                                    filterable
                                    placeholder="Chọn đối tác"
                            >
                                <el-option
                                        v-for="item in lstBranch"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Tên tài khoản game</label>
                        <el-form-item prop="name">
                            <el-select
                                    v-model="searchForm.name"
                                    multiple
                                    clearable
                                    collapse-tags
                                    filterable
                                    placeholder="Chọn tài khoản game"
                            >
                                <el-option
                                        v-for="item in lstUser"
                                        :key="item.id"
                                        :label="item.userName"
                                        :value="item.userName"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Ngày</label>
                        <el-form-item prop="date">
                            <el-date-picker
                                    v-model="searchForm.date"
                                    type="date"
                                    value-format="x"
                                    placeholder="Chọn ngày tìm kiếm"
                                    style="width: 100%"
                            />
                        </el-form-item>
                    </div>
                    <div class="col-md-3" v-if="lstVatPham.length>0">
                        <label class="form-label">Hiển thị vật phẩm</label>
                        <el-select
                                v-model="sltVatPham"
                                multiple
                                clearable
                                collapse-tags
                                filterable
                                placeholder="Chọn vật phẩm"
                                popper-class="custom-header"
                                :max-collapse-tags="1"
                                @change=showVatPham(sltVatPham)
                        >
                            <el-option
                                    v-for="item in lstSelectVatPham"
                                    :label="item"
                                    :value="item"
                            />
                        </el-select>
                    </div>
                    <div class="col-md-2" v-if="sltVatPham.length>0" v-for="(item,index) in sltVatPham">
                        <label class="form-label">Số lượng:<br/> {{ item }}</label>
                        <el-form-item>
                            <el-input-number style="width: 100%" v-model="inputSoLuong[item]" :min="1" :step="10"/>
                        </el-form-item>
                    </div>
                    <div class="col-md-12 row justify-content-center mt-2">
                        <el-form-item>
                            <el-button @click="onSubmit('formSearch','search')" type="primary">
                                Tìm kiếm
                            </el-button>
                            <el-button @click="onSubmit('formSearch','export')" type="success">
                                Xuất excel
                            </el-button>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
            <div class="row widget-grid">
                <div class="table-responsive">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="tableData.length==0 && show">
                        <p>Không tìm thấy dữ liệu</p>
                        <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <table class="table table-dashed table-striped" v-if="tableData.length>0">
                        <thead>
                        <tr>
                            <th scope="col" width="50px">STT</th>
                            <th scope="col">User_name</th>
                            <th scope="col">Máy</th>
                            <th scope="col">Đối tác</th>
                            <th scope="col" v-for="(item,i) in lstVatPham" v-if="lstVatPham.length>0"
                                :style="getStyle(item)">
                                {{ item }}
                            </th>
                            <th scope="col">Hành động</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in tableData" :key="index">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>{{ item.username }}</td>
                            <td>{{ item.device }}</td>
                            <td>{{ item.branch }}</td>
                            <td v-for="(vp,i) in lstVatPham" v-if="lstVatPham.length>0"
                                :style="getStyleSoLuong(vp,getVatPham(item.vatPham, vp))">
                                {{ getVatPham(item.vatPham, vp) }}
                            </td>
                            <td>
                                <button class="btn btn-primary" type="button" data-bs-toggle="modal"
                                        data-bs-target=".modal-user" @click="showModal(item)">Chi tiết
                                </button>

                            </td>
                        </tr>
                        </tbody>

                    </table>
                </div>
            </div>
            <div v-if="pagging.totalPage>0">
                <el-pagination
                        v-model:current-page="pagging.current_page"
                        v-model:page-size="pagging.size"
                        :page-sizes="[10, 20, 50, 100]"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pagging.total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                />
            </div>
        </div>
        <div class="modal fade modal-user" tabindex="-1" role="dialog" aria-labelledby="userModal"
             aria-hidden="true">
            <Modaluser :user='user'/>
        </div>
    </div>
</template>

<script>
import {get, post, put, remove} from '/src/m_utils/rest_api';
import Modaluser from './modal/modal_user.vue';
import ExcelJS from "exceljs";

export default {
    components: {
        'Modaluser': Modaluser,
    },
    data() {
        return {
            show: false,
            sltVatPham: [],
            lstSelectVatPham: ['Tất cả'],
            inputSoLuong: {},
            searchForm: {
                branch: '',
                name: [],
                date: Date.now(),
            },
            user: '',
            lstBranch: [
                {
                    value: 'DTS',
                    label: 'DTS'
                }
            ],
            lstUser: [],
            rules: {
                date: [
                    {required: true, message: 'Vui lòng chọn ngày', trigger: 'change'},
                    {
                        validator: (rule, value, callback) => {
                            if (value > Date.now()) {
                                callback(new Error('Vui lòng không chọn ngày lớn hơn ngày hôm nay'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur'
                    }
                ],
            },
            tableData: [],
            lstVatPham: [],
            pagging: {
                current_page: 1,
                size: 20,
                totalPage: 0,
                total: 0,
            },
            start: 0,
            limit: 20,
        };
    },
    methods: {
        getStyle(vatPham) {
            let arr = ['Năng Lượng', 'Coin', 'Pixel', 'Rep']
            if (arr.includes(vatPham)) {
                return ''
            }
            if (this.sltVatPham.includes(vatPham)) {
                return 'width:70px'
            }
            return 'display :none'
        },
        getStyleSoLuong(vatPham, so_luong) {
            let arr = ['Năng Lượng', 'Coin', 'Pixel', 'Rep']
            if (arr.includes(vatPham)) {
                return ''
            }
            if (this.sltVatPham.includes(vatPham)) {
                if (so_luong < this.inputSoLuong[vatPham]) {
                    return 'background-color: red; color: white'
                }
                return ''
            }
            return 'display :none'
        },
        showVatPham(sltVatPham) {
            if (sltVatPham.includes('Tất cả')) {
                this.sltVatPham = this.lstSelectVatPham.filter(item => item !== 'Tất cả');
            }
        },
        showModal(user) {
            this.user = user
        },
        onSubmit(formSearch, type) {
            this.$refs[formSearch].validate((valid) => {
                if (valid) {
                    if (type === 'search') {
                        this.onSearch()
                    } else {
                        this.onExport()
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        handleCurrentChange(page) {
            this.pagging.current_page = page;
            this.start = (page - 1) * this.limit
            this.onSearch()
        },
        handleSizeChange(size) {
            this.limit = size
            this.pagging.size = size
            this.onSearch()
        },
        async onSearch() {
            this.$store.dispatch('setLoading', true);
            const url = '/pixels-game/user/get-all-user-items'
            let start_time = this.searchForm.date
            let end_time = start_time + 86399999
            let params = {
                'startTime': start_time,
                'endTime': end_time,
                'start': this.start,
                'limit': this.limit,
            }
            if (this.searchForm.name.length > 0) {
                params.users = this.searchForm.name
            }
            try {
                const res = await post(url, params);
                this.lstVatPham = []
                this.sltVatPham = []
                this.lstSelectVatPham = ['Tất cả']
                if (res.rc == 0) {
                    this.tableData = res.item.content;
                    this.pagging.total = res.item.totalElements || 1;
                    this.pagging.totalPage = res.item.totalPages || 0;
                    this.show = true;
                    this.getLstVatPham(res)
                    this.$store.dispatch('setLoading', false);
                } else {
                    this.tableData = [];
                    this.lstVatPham = [];
                    this.$store.dispatch('setLoading', false);
                }
            } catch (e) {
                console.log(e)
                this.tableData = [];
                this.lstVatPham = [];
                this.$store.dispatch('setLoading', false);
            }
        },
        getVatPham(vatPham, name) {
            let so_luong = 0
            let item = vatPham.find(item => item.itemName === name)
            if (item) {
                so_luong = item.quantity
            }
            if (name == 'Năng Lượng' && so_luong == 1) {
                so_luong = 1000
            }
            return so_luong
        },
        getLstVatPham(res) {
            let allVatPham = [];
            res.item.content.forEach(item => {
                allVatPham.push(...item.vatPham)
            })
            let lstVatPham = []
            allVatPham.forEach(item => {
                let vp = lstVatPham.find(vp => vp === item.itemName)
                let arr = ['Năng Lượng', 'Coin', 'Pixel', 'Rep']
                if (!vp && !arr.includes(item.itemName)) {
                    this.lstSelectVatPham.push(item.itemName)
                    this.inputSoLuong[item.itemName] = 0
                }
                if (!vp) {
                    lstVatPham.push(item.itemName)
                }
            })
            this.lstVatPham = lstVatPham
        },
        getLstVatPhamExport(dataExport) {
            let allVatPham = [];
            dataExport.forEach(item => {
                allVatPham.push(...item.vatPham)
            })
            let lstVatPham = []
            let arr = ['Năng Lượng', 'Coin', 'Pixel', 'Rep']
            allVatPham.forEach(item => {
                let vp = lstVatPham.find(vp => vp === item.itemName)
                if (!vp && !arr.includes(item.itemName)) {
                    lstVatPham.push(item.itemName)
                }
            })
            return lstVatPham;
        },
        async onExport() {
            const url = '/pixels-game/user/get-all-user-items'
            let start_time = this.searchForm.date
            let end_time = start_time + 86399999
            let params = {
                'startTime': start_time,
                'endTime': end_time,
                'users': this.searchForm.name || [],
                'start': 0,
                'limit': 1,
            }
            this.show = false;
            try {
                this.$store.dispatch('setLoading', true);
                const res = await post(url, params);
                if (res.rc == 0) {
                    let total = res.item.totalElements
                    let result = []
                    let requests = []

                    if (total > 0) {
                        for (let i = 0; i < total; i += 500) {
                            result.push(i);
                        }
                        for (let i = 0; i < result.length; i++) {
                            let start = result[i]
                            let param = JSON.parse(JSON.stringify(params))
                            param.limit = 500;
                            param.start = start;
                            requests.push(this.fetchDsExport(param))
                        }
                        try {
                            const results = await Promise.all(requests);
                            const allPosts = results.flat();
                            let dataExport = []
                            allPosts.forEach((response, index) => {
                                if (Array.isArray(response.content)) {
                                    let cac_ban_ghi = response.content;
                                    for (let i = 0; i < cac_ban_ghi.length; i++) {
                                        dataExport.push(cac_ban_ghi[i]);
                                    }
                                }

                            });
                            if (dataExport.length) {
                                let lstVatPhamExport = this.getLstVatPhamExport(dataExport)
                                this.exportExcel(dataExport, lstVatPhamExport, this.searchForm.date)
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: "Thông báo",
                                    text: 'Không tìm thấy dữ liệu để xuất',
                                });
                            }
                        } catch (e) {
                            console.error('Error fetching multiple posts:', e);
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: "Thông báo",
                            text: 'Không tìm thấy dữ liệu để xuất',
                        });
                    }
                    this.$store.dispatch('setLoading', false);
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: "Thông báo",
                        text: 'Xuất dữ liệu thất bại',
                    });
                    this.$store.dispatch('setLoading', false);
                }
            } catch (e) {
                this.$swal({
                    icon: 'warning',
                    title: "Thông báo",
                    text: 'Xuất dữ liệu thất bại',
                });
                console.log(e)
                this.$store.dispatch('setLoading', false);
            }
        },
        async fetchDsExport(params) {
            const url = '/pixels-game/user/get-all-user-items'
            try {
                const res = await post(url, params);
                return res.item

            } catch (error) {
                console.error(`Error fetching posts from start ${start}:`, error);
                return [];
            }
        },
        exportExcel(data, lstVatPham, date) {
            let wb = new ExcelJS.Workbook();
            let sheet = wb.addWorksheet('Sheet1', {
                pageSetup: {
                    paperSize: 9,
                    fitToPage: true,
                    fitToHeight: 0,
                    fitToWidth: 1,
                }
            });

            let col_vat_pham = lstVatPham.length
            let ngay = this.convertTimestampToDate(date)
            sheet.getCell('A1').value = "Báo cáo dữ liệu vật phẩm ngày: " + ngay;
            sheet.mergeCells(1, 1, 1, 8 + col_vat_pham)
            let row = sheet.getRow(1);
            row.font = {
                name: 'Times New Roman',
                size: 12,
                bold: true
            }
            row.eachCell(function (cell, index) {
                cell.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center'};
            });
            let title_row = ['TT', 'User_name account', 'Máy', 'Đối tác', 'Năng Lượng', 'Coin', 'Pixel', 'Trust']

            lstVatPham.forEach(item => {
                title_row.push(item)
            })
            sheet.addRow()
            sheet.addRow(title_row)
            row = sheet.getRow(3);
            row.font = {
                name: 'Times New Roman',
                size: 12,
                bold: true
            }
            row.eachCell(function (cell, index) {
                cell.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center'};
                row.eachCell(function (cell, index) {
                    cell.border = {
                        top: {style: 'thin'},
                        left: {style: 'thin'},
                        bottom: {style: 'thin'},
                        right: {style: 'thin'}
                    }
                    cell.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center'};
                });
            });
            sheet.columns.forEach((column, index) => {
                if (index == 0) {
                    column.width = 7;
                } else if (index == 1) {
                    column.width = 15
                }
            });
            data.forEach((item, index) => {
                let obj = [
                    index + 1,
                    item.username,
                    item.device,
                    item.branch,
                    this.getVatPham(item.vatPham, 'Năng lượng'),
                    this.getVatPham(item.vatPham, 'Coin'),
                    this.getVatPham(item.vatPham, 'Pixel'),
                    this.getVatPham(item.vatPham, 'Rep'),
                ]
                lstVatPham.forEach(itemName => {
                    obj.push(this.getVatPham(item.vatPham, itemName))
                })
                row = sheet.addRow(obj)
                row.font = {
                    name: 'Times New Roman',
                    size: 11,
                    bold: false
                };
                row.eachCell(function (cell, index) {
                    cell.border = {
                        top: {style: 'thin'},
                        left: {style: 'thin'},
                        bottom: {style: 'thin'},
                        right: {style: 'thin'}
                    }
                    cell.alignment = {wrapText: true, vertical: 'middle', horizontal: 'center'};
                });
            })
            wb.xlsx.writeBuffer().then(function (data) {
                const blob = new Blob([data],
                    {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = 'Bao_cao_du_lieu_vat_pham_ngay_' + ngay + '.xlsx';
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        },
        convertTimestampToDate(timestamp) {
            // Tạo một đối tượng Date từ timestamp
            const date = new Date(timestamp);

            // Lấy ngày, tháng và năm từ đối tượng Date
            const day = date.getDate();
            const month = date.getMonth() + 1; // Tháng bắt đầu từ 0 nên cần cộng thêm 1
            const year = date.getFullYear();

            // Định dạng ngày, tháng thành chuỗi có 2 chữ số
            const formattedDay = day < 10 ? `0${day}` : day;
            const formattedMonth = month < 10 ? `0${month}` : month;

            // Trả về chuỗi ngày/tháng/năm
            return `${formattedDay}/${formattedMonth}/${year}`;
        },
        async getListUser() {
            this.$store.dispatch('setLoading', true);
            let start = 0;
            let limit = 100;
            let url = "/pixels-game/user/get-user?start=" + start + "&limit=" + limit;
            let param = {};
            const res = await post(url, param)
            if (res.rc == 0) {
                if (res.item.totalElements > 100) {
                    this.lstUser = res.item.content
                    let requests = []
                    for (let i = 100; i < res.item.totalElements; i += 100) {
                        requests.push(this.fetchDsUser(i))
                        try {
                            const results = await Promise.all(requests);
                            const allPosts = results.flat();
                            allPosts.forEach((response, index) => {
                                if (Array.isArray(response.content)) {
                                    let cac_ban_ghi = response.content;
                                    for (let i = 0; i < cac_ban_ghi.length; i++) {
                                        this.lstUser.push(cac_ban_ghi[i]);
                                    }
                                }
                            });
                            this.$store.dispatch('setLoading', false);

                        } catch (e) {
                            console.log(e)
                            this.$store.dispatch('setLoading', false);
                        }
                    }
                } else {
                    this.lstUser = res.item.content
                    this.$store.dispatch('setLoading', false);
                }
            } else {
                this.lstUser = []
                this.$store.dispatch('setLoading', false);
            }
        },
        async fetchDsUser(start) {
            let url = "/pixels-game/user/get-user?start=" + start + "&limit=100";
            let params = {}
            try {
                const res = await post(url, params);
                return res.item
            } catch (error) {
                console.error(`Error fetching posts from start ${start}:`, error);
                return [];
            }
        }
    },
    mounted() {
        this.onSearch()
        this.getListUser()
    }
};
</script>

