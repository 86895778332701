<template>
    <div class="row">
        <div class="col-xl-4 col-sm-6 box-col-6">
            <div class="col-xl-12">
                <div class="card widget-1">
                    <div class="card-body">
                        <div class="widget-content">
                            <div class="widget-round primary">
                                <div class="bg-round">
                                    <svg class="svg-fill" >
                                        <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#user-visitor`"></use>
                                    </svg>
                                    <svg class="half-circle svg-fill">
                                        <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#halfcircle`"></use>
                                    </svg>
                                </div>

                            </div>
                            <div>
                                <h4>{{ total_user }}</h4>
                                <span class="f-light">
                                Tài khoản game
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-sm-6 box-col-6" v-if="dataDashboard.length>0" v-for="item in dataDashboard"
             :key="item">
            <div class="col-xl-12">
                <div class="card widget-1">
                    <div class="card-body">
                        <div class="widget-content">
                            <div :class="item.widgetClass">
                                <div class="bg-round">
                                    <svg class="svg-fill" v-if="!item.img">
                                        <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${item.svgIcon1}`"></use>
                                    </svg>
                                    <img v-else :src="item.img" alt="Image" style="width: 100px; height: auto;"/>

                                    <svg class="half-circle svg-fill">
                                        <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${item.svgIcon2}`"></use>
                                    </svg>
                                </div>

                            </div>
                            <div>
                                <h4>{{  parseFloat(item.number.toFixed(2)) }}</h4>
                                <span class="f-light">
                                {{ item.title }}
                            </span>
                            </div>
                        </div>
                        <div :class="item.growthClass">
                            <i :class="item.iconClass"></i><span>{{ item.growthNumber }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {post} from "@/m_utils/rest_api";

export default {
    data() {
        return {
            dataDashboard:[]
        }
    },
    props:['total_user'],
    methods: {
        async getDashboard() {
            this.$store.dispatch('setLoading', true);
            let url ='/pixels-game/user/home'
            let now = this.getStartOfDayTimestamp()
            let yesterday = now - 86400000*6
            let params_now = {
                'start': 0,
                'limit': 20,
                'startTime': now - 86400000*6,
                'endTime': now + 86399999,
            };
            let params_yesterday = {
                'start': 0,
                'limit': 20,
                'startTime': yesterday - 86400000*7 ,
                'endTime': yesterday-1,
            };
            const res_now = await post(url,params_now)
            if(res_now.rc==0)
            {
                if(res_now.item.length>0){
                    const res_yesterday = await post(url,params_yesterday)
                    let obj = {}
                    res_now.item.forEach(item=>{
                        if(item.itemName!="Rep" && item.itemName!="Năng Lượng")
                        {
                            obj = {
                                svgIcon2: "halfcircle",
                                number: item.quantity,
                                title: item.itemName,
                            }
                            if(item.itemName=='Coin'){
                                obj.svgIcon1 ='doller-return'
                            }
                            else {
                                obj.svgIcon1 ='cart'
                            }
                            if(item.img)
                            {
                                obj.img = item.img
                            }
                            else {
                                obj.img = ''
                            }
                            let obj_yesterday = res_yesterday.item.find(item_yes =>item_yes.itemName === item.itemName)
                            if(obj_yesterday){
                                if(obj_yesterday.quantity> item.quantity)
                                {
                                    obj.widgetClass ="widget-round secondary"
                                    obj.growthClass= "font-secondary f-w-500"
                                    let rate =( (obj_yesterday.quantity - item.quantity)/obj_yesterday.quantity)*100
                                    rate= Math.round(rate)
                                    obj.growthNumber ='-'+rate+'%'
                                    obj.iconClass='icon-arrow-down icon-rotate me-1'
                                }
                                else {
                                    obj.widgetClass ="widget-round success"
                                    obj.growthClass= "font-success f-w-500"
                                    let rate =( ( item.quantity-obj_yesterday.quantity )/obj_yesterday.quantity)*100
                                    rate= Math.round(rate)
                                    obj.growthNumber ='+'+rate+'%'
                                    obj.iconClass='icon-arrow-up icon-rotate me-1'
                                }
                            }
                            else {
                                obj.widgetClass ="widget-round success"
                                obj.growthClass= "font-success f-w-500"
                                obj.growthNumber ='+100%'
                                obj.iconClass='icon-arrow-up icon-rotate me-1'
                            }
                            this.dataDashboard.push(obj)
                        }
                    })
                }
                else {
                    this.dataDashboard = []
                }
            }
            this.$store.dispatch('setLoading', false);

        },
        getStartOfDayTimestamp() {
            // Lấy datetime hiện tại
            const now = new Date();

            // Tạo datetime mới với giờ phút giây đặt về 0
            const startOfDay = new Date(now.getFullYear(), now.getMonth(), (now.getDate()));

            // Trả về timestamp
            return startOfDay.getTime();
        }
    },
    mounted() {
        this.getDashboard()
    }
}
</script>
