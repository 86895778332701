<template>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="userModal">Chi tiết tài khoản: {{ user.username }}</h4>
                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <table class="table table-lg">
                    <thead>
                    <tr>
                        <th scope="col">STT</th>
                        <th scope="col">Tên vật phẩm</th>
                        <th scope="col">Số lượng</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in user.vatPham">
                        <td>{{index+1}}</td>
                        <td>{{item.itemName}}</td>
                        <td>{{item.quantity}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import {post} from "@/m_utils/rest_api";

export default {
    data() {
        return {
            tableData: []
        };
    },
    props: ["user"],
}
</script>