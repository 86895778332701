<template>
    <div>
        <Breadcrumbs title="Dashboard"/>
        <div class="container-fluid">
            <div class="row widget-grid">
                <div class="col-xxl-4 col-sm-6 box-col-6">
                    <WelcomeCard />
                </div>
                <div class="col-xxl-8 col-sm-6 box-col-6">
                    <PurchaseSaleCard :total_user="total_user"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import WelcomeCard from "@/pages/dashboard/index/WelcomeCard.vue";
import PurchaseSaleCard from "@/pages/dashboard/index/PurchaseSaleCard.vue";
import OrdersProfitCard from "@/pages/dashboard/index/OrdersProfitCard.vue";
import {get, post, put, remove} from '/src/m_utils/rest_api';

export default {
    components: {
        WelcomeCard,
        PurchaseSaleCard,
        OrdersProfitCard,
    },
    data() {
        return {
            total_user: 0,
        }
    },
    methods: {
        async getTotalUser() {
            this.$store.dispatch('setLoading', true);
            let url = '/pixels-game/user/get-user?start=0&limit=1'
            let params = {}
            try {
                const res = await post(url, params)
                if (res.rc == 0) {
                    this.total_user = res.item.totalElements
                }
                this.$store.dispatch('setLoading', false);
            } catch (e) {
                console.log(e)
                this.$store.dispatch('setLoading', false);

            }

        }
    },
    mounted() {
        this.getTotalUser()
    }
};
</script>
