import {createStore} from 'vuex'

import layout from './modules/layout';
import menu from './modules/menu';

export default createStore({
    state: { show_loading: false, isActive: false},
    getters: {
        show_loading: (state) => {
            return state.show_loading
        },
    },
    mutations: {
        change(state) {
            state.isActive = !state.isActive
        },
        SET_LOADING(state, status) {
            state.show_loading = status;
        }
    },
    actions: {
        setLoading({ commit }, status) {
            commit('SET_LOADING', status);
        }
    },
    modules: {
        alert,
        layout,
        menu,
    }
});

