<template>
    <head>
        <title>Trang chủ</title>
    </head>
    <div class="loader-wrapper" v-if="loading">
        <div class="loader-index"><span></span></div>
        <svg>
            <defs></defs>
        </svg>
    </div>
    <router-view v-else></router-view>
</template>

<script>
import { mapState } from 'vuex';

export default {

    name: 'App',
    data() {
        return {
            loading: true,
        }
    },
    watch: {
        '$route'() {
            setTimeout(() => {
                this.loading = !this.loading
            }, 1000)
            this.loading = !this.loading
        }
    },
    mounted() {
        this.loading = false;
    },
    computed: {
        ...mapState(['show_loading']),
    }
}
</script>

