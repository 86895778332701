<template>
    <div>
        <Breadcrumbs title="Danh sách tài khoản"/>
        <el-form
                ref="ruleFormRef"
                style="width: 100%"
                :model="ruleForm"
                :rules="rules"
                label-width="auto"
                class="demo-ruleForm"
                label-position="left"
        >
            <div class="row">

                <div class="col-md-3">
                    <label class="form-label">Tên tài khoản</label>
                    <el-form-item prop="name">
                        <el-input v-model="ruleForm.name"/>
                    </el-form-item>
                </div>
                <div class="col-md-3">
                    <label class="form-label">Đối tác</label>
                    <el-form-item prop="name">
                        <el-input v-model="ruleForm.name"/>
                    </el-form-item>
                </div>
                <div class="col-md-3">
                    <label class="form-label">Thời gian</label>
                    <el-form-item prop="name">
                        <el-input v-model="ruleForm.name"/>
                    </el-form-item>
                </div>
                <div class="col-md-12 row justify-content-center">
                    <el-form-item>
                        <el-button type="primary">
                            Tìm kiếm
                        </el-button>
                        <el-button>Xuất excel</el-button>
                    </el-form-item>
                </div>
            </div>
        </el-form>
        <div class="row justify-content-end" style="margin:5px" >
            <el-button type="success" style="width:150px">Thêm mới</el-button>
        </div>
        <div class="container-fluid">
            <div class="row widget-grid">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="name" label="Tài khooản"/>
                    <el-table-column prop="state" label="Role" />
                    <el-table-column prop="branch" label="Đối tác" />
                    <el-table-column prop="date" label="Thời gian tạo"  />
                    <el-table-column fixed="right" label="Hành động">
                        <template #default>
                            <el-button link type="primary" size="small" @click="handleClick">
                                Chi tiết
                            </el-button>
                            <el-button link type="primary" size="small">Chỉnh sửa</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            ruleForm: {
                name: '',
            },
            rules: {
                name: [
                    {required: true, message: 'Vui lòng nhập', trigger: 'blur'},
                ],
            },
            tableData: [
                {
                    date: '2016-05-03',
                    name: 'Tom',
                    state: 'user',
                    city: '0.0',
                    address: '0.0',
                    branch:'DTS',
                },
                {
                    date: '2016-05-03',
                    name: 'Tom',
                    state: 'user',
                    city: '0.0',
                    address: '0.0',
                    branch:'DTS',
                },
                {
                    date: '2016-05-03',
                    name: 'Tom',
                    state: 'admin',
                    city: '0.0',
                    address: '0.0',
                    branch:'DTS',
                },
                {
                    date: '2016-05-03',
                    name: 'Tom',
                    state: 'user',
                    city: '0.0',
                    address: '0.0',
                    branch:'DTS',
                },
                {
                    date: '2016-05-03',
                    name: 'Tom',
                    state: 'admin',
                    city: '0.0',
                    address: '0.0',
                    branch:'DTS',

                },
            ]
        };
    },
    // methods: {
    //     async getDuLieu() {
    //         let url = "/pixels-game/user/get-user-items";
    //         let param = {
    //             'users': 'thanhnam217',
    //             'start': 0,
    //             'limit': 10
    //         };
    //         try {
    //             let res = await post(url, param);
    //             console.log(res);
    //             if (res.rc == 0) {
    //                 console.log(res.item);
    //                 this.tableData = res.item.content;
    //             } else {
    //                 this.tableData = [];
    //             }
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //             this.tableData = [];
    //         }
    //     }
    // },
    // mounted() {
    //     this.getDuLieu();
    // }
};
</script>

