export var menuItems = {
    "data": [
        {
            "headTitle1": "Admin",
            "type": "headtitle"
        },
        {
            "title": "Dashboard",
            "icon": "stroke-home",
            "iconf": "fill-home",
            "type": "link",
            "badgeType": "light-primary",
            "active": true,
            "path": "/",
        },
        {
            "title": "Báo cáo thống kê",
            "icon": "stroke-file",
            "iconf":"fill-file",
            "type": "sub",
            "active": false,
            "children": [
                {
                    "path": "/report-day",
                    "title": "Báo cáo theo ngày",
                    "type": "link",
                    "active":false
                },
                {
                    "path": "/report-week",
                    "title": "Báo cáo theo tuần",
                    "type": "link",
                    "active":false
                },
                {
                    "path": "/report-month",
                    "title": "Báo cáo theo tháng",
                    "type": "link",
                    "active":false
                },
            ]
        },
        {
            "title": "Quản lý hệ thống",
            "icon": "stroke-social",
            "iconf":"fill-social",
            "type": "sub",
            "active": false,
            "children": [
                {
                    "path": "/user",
                    "title": "Quản lý đối tác",
                    "type": "link",
                    "active":false
                },
                {
                    "path": "/item",
                    "title": "Quản lý vật phẩm",
                    "type": "link",
                    "active":false
                },
                {
                    "path": "",
                    "title": "Quản lý vận hành",
                    "type": "link",
                    "active":false
                },

            ]
        },
        {
            "headTitle1": "User",
            "type": "headtitle"
        },
        {
            "title": "Dashboard",
            "icon": "stroke-home",
            "iconf": "fill-home",
            "type": "link",
            "badgeType": "light-primary",
            "active": true,
            "path": "/",
        },
        {
            "title": "Báo cáo thống kê",
            "icon": "stroke-file",
            "iconf":"fill-file",
            "type": "sub",
            "active": false,
            "children": [
                {
                    "path": "",
                    "title": "Báo cáo theo ngày",
                    "type": "link",
                    "active":false
                },
                {
                    "path": "",
                    "title": "Báo cáo theo tuần",
                    "type": "link",
                    "active":false
                },
                {
                    "path": "",
                    "title": "Báo cáo theo tháng",
                    "type": "link",
                    "active":false
                },
            ]
        },
        {
            "title": "Danh sách tài khoản game",
            "icon": "stroke-user",
            "iconf":"fill-user",
            "type": "link",
            "badgeType": "light-primary",
            "active": true,
            "path": "/account-game",
        },

    ]
}