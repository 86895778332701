<template>
    <div>
        <Breadcrumbs title="Danh sách tài khoản trong game của đối tác"/>
        <div class="container-fluid">
            <el-form
                    ref="searchForm"
                    style="width: 100%"
                    :model="searchForm"
                    :rules="rules"
                    label-width="auto"
                    class="demo-ruleForm"
                    label-position="left"
            >
                <div class="row">
                    <div class="col-md-3">
                        <label class="form-label">Đối tác</label>
                        <el-form-item prop="name">
                            <el-input v-model="searchForm.branch"/>
                        </el-form-item>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Tên tài khoản game</label>
                        <el-form-item prop="name">
                            <el-input v-model="searchForm.name"/>
                        </el-form-item>
                    </div>
                    <div class="col-md-12 row justify-content-center">
                        <el-form-item>
                            <el-button type="primary">
                                Tìm kiếm
                            </el-button>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
            <div class="row justify-content-end" style="margin:5px">
                <el-button type="success" style="width:150px">Thêm mới</el-button>
            </div>
            <div class="row widget-grid">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column label="STT" type="index" width="100px" :index="getIndex"></el-table-column>
                    <el-table-column label="Tên tài khoản" prop="userName"></el-table-column>
                    <el-table-column label="Đối tác" prop="branch"></el-table-column>
                    <el-table-column label="ID tài khoản" prop="userId"></el-table-column>
                    <el-table-column label="Máy quản lý" prop="device"></el-table-column>
                    <el-table-column label="Profile quản lý" prop="profileName"></el-table-column>
                </el-table>
            </div>
            <div v-if="pagging.totalPage>0">
                <el-pagination
                        v-model:current-page="pagging.current_page"
                        v-model:page-size="pagging.size"
                        :page-sizes="[20, 50, 100]"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pagging.total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                />
            </div>
        </div>

    </div>
</template>

<script>
import {get, post, put, remove} from '/src/m_utils/rest_api';

export default {
    data() {
        return {
            searchForm: {
                name: '',
                branch: '',
            },
            pagging: {
                current_page: 1,
                size: 20,
                totalPage: 0,
                total: 0,
            },
            start: 0,
            limit: 20,
            rules: {},
            tableData: []
        };
    },
    methods: {
        getIndex(index) {
            return (this.pagging.current_page-1)*this.limit + index + 1
        },
        handleCurrentChange(page) {
            this.pagging.current_page = page;
            this.start = (page - 1) * this.limit
            this.getDuLieu()
        },
        handleSizeChange(size) {
            this.limit = size
            this.pagging.size = size
            this.getDuLieu()
        },
        async getDuLieu() {
            this.$store.dispatch('setLoading', true);
            let url = "/pixels-game/user/get-user?start="+this.start+"&limit="+this.limit;
            let param = {};
            this.pagging.totalPage = 0;
            this.pagging.total = 0;
            try {
                let res = await post(url, param);
                if (res.rc == 0) {
                    this.tableData = res.item.content;
                    this.pagging.total = res.item.totalElements;
                    this.pagging.totalPage = res.item.totalPages;
                } else {
                    this.tableData = [];
                }
                this.$store.dispatch('setLoading', false);
            } catch (error) {
                this.$store.dispatch('setLoading', false);
                console.error("Error fetching data:", error);
                this.tableData = [];
            }
        }
    },
    mounted() {
        this.getDuLieu();
    }
};
</script>

