<template>
    <div>
        <Breadcrumbs main="Báo cáo thống kê" title="Báo cáo dữ liệu của các tài khoản theo tuần"/>
        <div class="container-fluid">
            <el-form
                    ref="formSearch"
                    style="width: 100%"
                    :model="searchForm"
                    :rules="rules"
                    label-width="auto"
                    class="demo-ruleForm"
                    label-position="left"
            >
                <div class="row">
                    <div class="col-md-3">
                        <label class="form-label">Đối tác</label>
                        <el-form-item prop="branch">
                            <el-select
                                    v-model="searchForm.branch"
                                    filterable
                                    placeholder="Chọn đối tác"
                            >
                                <el-option
                                        v-for="item in lstBranch"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Tên tài khoản game</label>
                        <el-form-item prop="name">
                            <el-select
                                    v-model="searchForm.name"
                                    filterable
                                    placeholder="Chọn tài khoản game"
                            >
                                <el-option
                                        v-for="item in lstUser"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="col-md-3">
                        <label class="form-label">Tuần</label>
                        <el-form-item prop="date">
                            <el-date-picker
                                    v-model="searchForm.date"
                                    type="week"
                                    format="[Tuần] ww"
                                    value-format="x"
                                    placeholder="Chọn tuần tìm kiếm"
                                    style="width: 100%"
                            />
                        </el-form-item>
                    </div>
                    <div class="col-md-12 row justify-content-center">
                        <el-form-item>
                            <el-button @click="onSubmit('formSearch')" type="primary">
                                Tìm kiếm
                            </el-button>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
            <div class="row widget-grid">
                <div class="table-responsive">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="tableData.length==0 && show">
                        <p>Không tìm thấy dữ liệu</p>
                        <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <table class="table table-dashed" v-if="tableData.length>0">
                        <thead>
                        <tr>
                            <th scope="col">STT</th>
                            <th scope="col">User_name</th>
                            <th scope="col">Máy</th>
                            <th scope="col">Đối tác</th>
                            <th scope="col" v-if="lstVatPham.length>0" v-for="(item,i) in lstVatPham">
                                {{ item }}
                            </th>
                            <th scope="col">Hành động</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in tableData" :key="index">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>{{ item.username }}</td>
                            <td>{{ item.device }}</td>
                            <td>{{ item.branch }}</td>
                            <td v-if="lstVatPham.length>0" v-for="(vp,i) in lstVatPham">
                                {{ getVatPham(item.vatPham, vp) }}
                            </td>
                            <td>
                                <button class="btn btn-primary" type="button" data-bs-toggle="modal"
                                        data-bs-target=".modal-user" @click="showModal(item)">Chi tiết
                                </button>

                            </td>
                        </tr>
                        </tbody>

                    </table>
                </div>

            </div>
            <!--            <div class="demo-pagination-block mt-5">-->
            <!--                <el-pagination-->
            <!--                        current-page="1"-->
            <!--                        page-size="5"-->
            <!--                        :page-sizes="[10, 20, 30, 40]"-->
            <!--                        size="5"-->
            <!--                        layout="total, sizes, prev, pager, next, jumper"-->
            <!--                        :total="tableData.length"-->
            <!--                        @size-change="handleSizeChange"-->
            <!--                        @current-change="handleCurrentChange"-->
            <!--                />-->
            <!--            </div>-->
        </div>
        <div class="modal fade modal-user" tabindex="-1" role="dialog" aria-labelledby="userModal"
             aria-hidden="true">
            <Modaluser :user='user'/>
        </div>
    </div>
</template>

<script>
import {get, post, put, remove} from '/src/m_utils/rest_api';
import Modaluser from "@/pages/report/modal/modal_user.vue";

export default {
    components: {Modaluser},
    data() {
        return {
            lstBranch: [
                {
                    value: 'DTS',
                    label: 'DTS'
                }
            ],
            lstUser: [
                {
                    value: 'thanhnam217',
                    label: 'thanhnam217'
                },
                {
                    value: 'manh-mt-14',
                    label: 'manh-mt-14'
                },
                {
                    value: 'manh-mt-17',
                    label: 'manh-mt-17'
                }
            ],
            searchForm: {
                branch: '',
                name: '',
                date: '',
            },
            show: false,
            user: {},
            rules: {
                date: [
                    {required: true, message: 'Vui lòng chọn tuần', trigger: 'change'},
                    {
                        validator: (rule, value, callback) => {
                            if (value > Date.now()) {
                                callback(new Error('Vui lòng không chọn tuần lớn hơn tuần hiện tại'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur'
                    }
                ],
            },
            tableData: [],
            lstVatPham: [],
        };
    },
    methods: {
        showModal(user) {
            this.user = user
        },
        onSubmit(formSearch) {
            this.$refs[formSearch].validate((valid) => {
                if (valid) {
                    this.onSearch()
                } else {
                    console.log('error submit!!');
                    this.tableData = [];
                    this.lstVatPham = [];
                    return false;
                }
            });
        },
        async onSearch() {
            const url = '/pixels-game/user/get-all-user-items'
            let start_time = this.searchForm.date
            let end_time = start_time + 604799999
            let params = {
                'startTime': start_time,
                'endTime': end_time,
                'users': this.searchForm.name || '',
                'start': 0,
                'limit': 10,
            }
            try {
                const res = await post(url, params);
                this.lstVatPham = []
                if (res.rc == 0) {
                    this.tableData = res.item.content;
                    this.show = true;
                    this.getLstVatPham(res)
                } else {
                    this.tableData = [];
                    this.lstVatPham = [];
                }
            } catch (e) {
                console.log(e)
                this.tableData = [];
                this.lstVatPham = [];
            }
        },
        getVatPham(vatPham, name) {
            let so_luong = 0
            let item = vatPham.find(item => item.itemName === name)
            if (item) {
                so_luong = item.quantity
            }
            return so_luong
        },
        getLstVatPham(res) {
            let allVatPham = [];
            res.item.content.forEach(item => {
                allVatPham.push(...item.vatPham)
            })
            let lstVatPham = []
            allVatPham.forEach(item => {
                let vp = lstVatPham.find(vp => vp === item.itemName)
                if (!vp) {
                    lstVatPham.push(item.itemName)
                }
            })
            this.lstVatPham = lstVatPham
        }
    },
    mounted() {
    }
};
</script>

