<template>
    <router-link to="/">
        <img
                class="img-fluid for-light"
                src="../../assets/images/logo/logo-icon.png"
                alt=""
        /><img
            class="img-fluid for-dark"
            src="../../assets/images/logo/logo-icon.png"
            alt=""
    />
    </router-link>
</template>
<script>

export default {
    name: 'Logo',
};
</script>
